import dynamic from 'next/dynamic';
import { serialize, parse } from 'cookie';
import { metaObj } from '@utils/meta';
import { CookieName } from '@utils/constants';
import dmqiService from '@services/dmqi.service';
import { encryptAES } from '@helpers/crypto.helper';
const HomeView = dynamic(() => import('@containers/home/views'));

export const getServerSideProps = async ({ query, req, res }: any) => {
  if (query?.paramfpe) {
    const response = await dmqiService.decryptFPEUrl({
      paramEncrypted: query?.paramfpe?.replaceAll(' ', '+')
    });

    if (response?.status?.code === '200') {
      const cookieParse = req.headers.cookie ? parse(req.headers.cookie) : { _Usm: '' };

      res.setHeader('Set-Cookie', [
        serialize(CookieName.qr, encryptAES(response.data.qr), { maxAge: 60 * 60 * 24 * 4 }),
        serialize(CookieName.phone, encryptAES(response.data.phone_no), {
          maxAge: 60 * 60 * 24 * 4
        }),
        serialize(CookieName.fprsId, encryptAES(response.data.fprs_id), {
          maxAge: 60 * 60 * 24 * 4
        }),
        serialize(CookieName.struk, encryptAES(response.data.struk), { maxAge: 60 * 60 * 24 * 4 })
      ]);

      return {
        props: {
          metaObj: metaObj
        },
        redirect: {
          permanent: false,
          destination: cookieParse[CookieName.user] ? '/card' : `/register?qr=${response.data.qr}`
        }
      };
    }
  }

  return {
    props: {
      metaObj: metaObj
    }
  };
};

export default HomeView;
